var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-row',{staticClass:"ministry"},[_c('a-row',{staticClass:"content-title",attrs:{"type":"flex"}},[_c('h2',{staticClass:"flex-3"},[_vm._v(" "+_vm._s(_vm.title[_vm.$i18n.locale])+" ")]),_c('a-row',{staticClass:"flex-no__wrap ml-auto wanted-search-input-wrapper",staticStyle:{"justify-content":"flex-end"},attrs:{"type":"flex"}})],1),_c('a-table',{key:_vm.$route.fullPath,attrs:{"columns":_vm.columns,"data-source":_vm.tableProps.results,"pagination":false},scopedSlots:_vm._u([{key:"mistake",fn:function(item){return [_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$router.push({
            name: 'spelling-mistake-detail',
            params: {
              id: item.id,
            },
          })}}},[_vm._v(" "+_vm._s(item.mistake || _vm.$t('NotIndicated'))+" ")])]}},{key:"description",fn:function(item){return [_vm._v(" "+_vm._s(item.description || _vm.$t('NotIndicated'))+" ")]}},{key:"fixed",fn:function(item){return [(item.fixed == true)?_c('div',[_vm._v(_vm._s(_vm.$t('Fixed')))]):_c('div',[_vm._v(_vm._s(_vm.$t('NoFixed')))])]}},{key:"date",fn:function(item){return [_vm._v(" "+_vm._s(item.created_at)+" ")]}},{key:"operation",fn:function(item){return [_c('button',{staticClass:"ant-btn ant-btn-primary",on:{"click":function($event){return _vm.$router.push({
            name: 'spelling-mistake-detail',
            params: { id: item.id },
          })}}},[_vm._v(" "+_vm._s(_vm.$t('More'))+"... ")])]}}])}),_c('a-row',{staticClass:"mt-1",staticStyle:{"padding-bottom":"15px"}},[_c('a-pagination',{attrs:{"current":_vm.page,"default-page-size":_vm.perPage,"total":+_vm.tableProps.count || 0},on:{"change":_vm.toPage}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }