<template>
  <a-row class="ministry">
    <a-row class="content-title" type="flex">
      <h2 class="flex-3">
        {{ title[$i18n.locale] }}
      </h2>

      <a-row
        class="flex-no__wrap ml-auto wanted-search-input-wrapper"
        style="justify-content: flex-end"
        type="flex"
      />
    </a-row>
    <a-table
      :key="$route.fullPath"
      :columns="columns"
      :data-source="tableProps.results"
      :pagination="false"
    >
      <template slot="mistake" slot-scope="item">
        <div
          style="cursor: pointer"
          @click="
            $router.push({
              name: 'spelling-mistake-detail',
              params: {
                id: item.id,
              },
            })
          "
        >
          {{ item.mistake || $t('NotIndicated') }}
        </div>
      </template>
      <template slot="description" slot-scope="item">
        {{ item.description || $t('NotIndicated') }}
      </template>
      <template slot="fixed" slot-scope="item">
        <div v-if="item.fixed == true">{{ $t('Fixed') }}</div>
        <div v-else>{{ $t('NoFixed') }}</div>
      </template>
      <template slot="date" slot-scope="item">
        {{ item.created_at }}
      </template>
      <template slot="operation" slot-scope="item">
        <button
          class="ant-btn ant-btn-primary"
          @click="
            $router.push({
              name: 'spelling-mistake-detail',
              params: { id: item.id },
            })
          "
        >
          {{ $t('More') }}...
        </button>
      </template>
    </a-table>
    <a-row class="mt-1" style="padding-bottom: 15px">
      <a-pagination
        :current="page"
        :default-page-size="perPage"
        :total="+tableProps.count || 0"
        @change="toPage"
      />
    </a-row>
  </a-row>
</template>

<script>
export default {
  data() {
    return {
      tableProps: {
        results: [],
        count: 2,
      },
      title: {
        uz: "Imloviy xato",
        oz: "Имловий хато",
        ru: "Орфографическая ошибка",
        en: "Spelling mistake",
      },
      loading: false,
      perPage: 10,
      page: 1,
      offset: 0,
      columns: [
        {
          title: this.$t('OrthographicLover'),
          key: "mistake",
          class: "wanted-fio-td",
          scopedSlots: {
            customRender: "mistake",
          },
          width: "35%",
        },
        {
          title:  this.$t('Description'),
          key: "description",
          class: "wanted-fio-td",
          scopedSlots: {
            customRender: "description",
          },
          width: "35%",
        },
        {
          title: this.$t('Status'),
          key: "fixed",
          class: "wanted-fio-td",
          scopedSlots: {
            customRender: "fixed",
          },
          width: "15%",
        },
        {
          title: this.$t('DataTransfers'),
          key: "date",
          class: "wanted-fio-td",
          scopedSlots: {
            customRender: "date",
          },
          width: "200px",
        },
        {
          title: this.$t('UsersColTitle2'),
          key: "operation",
          scopedSlots: { customRender: "operation" },
          width: "15%",
        },
      ],
    };
  },
  created() {
    this.fetchAppeals();
  },
  methods: {
    toPage(arg) {
      this.$router.push({ query: { page: arg } });
    },
    async fetchAppeals() {
      this.loading = true;
      this.offset = +this.$route.query.page || 1;
      this.page = this.offset;
      this.offset = (this.offset - 1) * this.perPage;
      try {
        const { data } = await this.$api.get("/admin/syntax-mistake/list", {
          params: {
            limit: this.perPage,
            offset: this.offset,
          },
        });
        if (data && data.data) {
          console.log(data);
          this.$set(this, "tableProps", {
            results: data.data.results || [],
            count: data.data.count,
          });
        }
      } catch (error) {
        console.error(error);
        this.loading = false;
      }

      this.loading = false;
    },
  },
};
</script>
<style>
.wanted-search-input-wrapper {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 430px;
  flex: 0 0 430px;
  justify-content: space-around;
}
.ministry .ant-table-pagination.ant-pagination {
  display: none;
}
</style>
